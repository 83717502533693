import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="copy-right">
        <span>
          E: <a href="mailto:contact@lokeshk.net">contact@lokeshk.net</a>
        </span>
        <span>
          T: <a href="tel:+408 641 1514">+408 641 1514</a>
        </span>
      </div>
      <div className="social-media">
        <p className="follow-label">Follow Me</p>
        <div className="social-icon">
          <a target="_blank" href="https://www.linkedin.com/in/lokesh-it/" title="Linkedin">
            {" "}
            <span className="fab fa-linkedin-in"></span>{" "}
          </a>
          {/* <a target="_blank" href="https://github.com" title="Github">
            {" "}
            <span className="fab fa-github"></span>{" "}
          </a> */}
          {/* <a target="_blank" href="https://www.facebook.com" title="Facebook">
            {" "}
            <span className="fab fa-facebook-f"></span>{" "}
          </a>
          <a target="_blank" href="https://www.instagram.com" title="Instagram">
            {" "}
            <span className="fab fa-instagram"></span>{" "}
          </a> */}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
