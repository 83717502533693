import React, { useState } from "react";

const Projects = () => {
  const [projects] = useState([
    {
      role: "Cloud Engineer/Automation Engineer",
      date: "Jan 2020 - Till date",
      alt: "Intel",
      src: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Intel_logo_%282006-2020%29.svg",
      text:"Automate building, deploying and validating multiple components."
    },
    {
      role: "Cloud Engineer",
      date: "Feb 2019 - Dec 2019",
      alt: "Apple",
      src: "http://incitrio.com/wp-content/uploads/2015/01/Apple_gray_logo.png",
      // src: "https://www.svgrepo.com/show/24736/apple.svg",
      text:"Orchestrate applications in On-premise and Public Cloud Providers."

    },
    {
      role: "System Administrator",
      date: "Apr 2018 - Feb 2019",
      alt: "Valin",
      src: "https://www.valin.com/sites/all/themes/custom/valin/logo.png",
      text:"Administer, Migrate and deploy database."

    },
    {
      role: "System Administrator/Data Engineer",
      date: "Aug 2016 - Apr 2018",
      alt: "tesla",
      src: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Tesla_Motors.svg",
      text:"Integrate On-premise database to Public cloud provider."

    },
    {
      role: "Software Developer",
      date: "Jun 2013 - Dec 2015",
      alt: "intone",
      src: "https://pbs.twimg.com/profile_images/2645261765/e72eb46e98eaff96aa7a1e62b9200dd3_400x400.jpeg",
      text:"Develop and Validate .NET based application."

    },
  ]);
  return (
    <div className="container-inner">
      <div className="block-title">
        <h2>Projects Involved</h2>
      </div>
      <hr />
      <div className="project-outer">
        {projects.map((project) => {
          return (
            <div className="project-box" key={project}>
              <span className="hover"></span>
              <div className="year-box">
                <h4 className="project-year">{project.role} <span>({project.date})</span></h4>
                <p className="project-details">{project.text}</p>
              </div>
              <div className="project-img">
                <img
                  alt={project.alt}
                  height="auto"
                  width="auto"
                  src={project.src}
                  title={project.alt}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Projects;
